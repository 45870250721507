.signup-form{
    display: flex;
    justify-content: center;
    padding: 10px;
    margin-top: 10%;
}

.signup-card{
    /* margin: 0 auto; */
    padding: 10px;
}