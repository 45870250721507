.highlight{
  color: red;
}

.custom-textarea textarea {
  padding: 10px; /* Add padding between text and box */
}

/* Custom CSS for always-visible scrollbars */
.always-visible-scrollbar::-webkit-scrollbar {
  visibility: visible;
}
.always-visible-scrollbar {
  scrollbar-width: thin;
  scrollbar-color: gray transparent;
}